@import "../src/sass/colors";

@import "../src/sass/layout";

html,
body {
  overflow: hidden;
  overscroll-behavior: none;
  background: #dae8f2;
}

body {
  height: 100%;
  position: fixed;
  /* prevent overscroll bounce*/
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  /* iOS velocity scrolling */
}

.helpContainer {
  background: #fff;
  border: 1px solid;
  width: 82.5vw;
  height: 90vh;
  position: absolute;
  top: 3.4vh;
  left: 3.4vw;
  margin: 0px;

  overflow: scroll;

  padding: 6px 18px;

  z-index: 10;

  box-shadow: 5px 5px 5px grey;

  .theCloser {
    float: right;
    cursor: pointer;
  }
}

.App {
  height: 100svh;
  width: 100vw;
  max-width: 480px;
  margin: 0px 50%;
  display: flex;
  flex-direction: column;
  margin-left: 50vw;
  transform: translateX(-50%);
  background: #fff;

  header {
    // background: linear-gradient(-90deg, #466f8d, 1% #253a4b, 100%);
    background: #466f8d;

    flex: flex-shrink 80px;
    overflow: hidden;
    margin: 0px;
    padding: 2px;
    color: white;

    .logo {
      max-height: 80px;
      max-width: auto;
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    h1,
    h2 {
      padding-right: 1em;
      margin: 2px;
    }
    menu {
      padding: 0px;
      margin: 2px;
      ul {
        list-style: none;
        margin: 0px;
        padding: 0px;
        li {
          display: inline-block;
          padding: 4px 12px 4px 0px;
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }
  .words-and-score {
    flex: 1 80px;
    display: flex;
    flex-direction: row;
    .words {
      flex: 1 75%;
      ul {
        list-style: none;
        margin: 0px;
        padding: 0px;
        li {
          list-style: none;
          display: inline-block;
          margin-right: 15px;
        }
      }
    }
    .score {
      flex: 1 25%;
      ul {
        list-style: none;
        margin: 0px;
        padding: 0px;
        li {
          list-style: none;
          display: block;
          margin-right: 15px;
        }
      }
    }
  }

  .tiles {
    flex: 1 50svh;
  }

  @media (max-device-width: 480px) {
    @include layout(60px, 40vh, 60svh);
    header {
      h1 {
        font-size: 1.2em;
      }
      h2 {
        display: none;
      }
    }
    .words-and-score {
      h2 {
        font-size: 1em;
        margin-bottom: 6px;
        font-weight: 600;
      }
    }
    .tiles {
      padding: 5px;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@import "../sass/circle";

/**

*/

.shuffle-the-letters {
  font-size: 46px;
  padding-left: 10px;
  position: absolute;
}

.circle-holder {
  border: 1px solid #bababa;
  width: 100%;
  height: 100%;
  font-size: 22px;
  text-transform: uppercase;
  user-select: none;

  $letter-size: 46px;

  @include generate-circle($circle-size: 400px, $letter-size: 60px);

  .letter {
    border: 1px solid lightgrey;
    border-radius: 50%;
    background: white;
    text-align: center;
    vertical-align: middle;
    height: 48px;
    width: 48px;

    span {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      line-height: $letter-size;
      outline: 1px solid lightgrey;
      pointer-events: none;
    }
    &.selected {
      background: lightblue;
      font-weight: bold;
      text-shadow: lightgrey;
    }
    &:hover {
      background: lightgrey;
      border-color: lightgrey;
    }
  }
}

@media (max-device-width: 480px) {
  $circle-size: 240px;
  .circle-holder {
    @include generate-circle($circle-size, 48px);
  }
}
// ipad pro
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  $circle-size: 400px;
  .circle-holder {
    @include generate-circle($circle-size, 48px);
  }
}

canvas {
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
  position: absolute;
}

.hold-all-the-things {
  position: relative;
  width: 100%;
  height: 100%;
  .circle-holder {
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
}

.selectedLetters {
  position: absolute;
  top: 0px;
  right: 0px;
}

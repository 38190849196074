@mixin layout($header-size, $words-and-score-size, $tile-size) {
  header {
    flex: $header-size;
  }

  .words-and-score {
    flex: calc($words-and-score-size - $header-size);
    overflow: hidden;
  }
  .tiles {
    border-top: 1px outset;
    flex: calc($tile-size - $header-size);
    overflow: hidden;

    display: flex;
    align-items: center;
    flex-direction: row;
  }
}

.scoring-panel {
  font-size: 0.9rem;

  .stats {
    border: 0px;
    padding: 4px;
    text-align: center;
  }

  table.score {
    border-top: 1px solid gray;

    padding: 4px;
    width: 90%;
    thead {
      font-weight: bold;
    }
    tbody {
      td:last-of-type {
        text-align: right;
      }
      td.pulse {
        animation-name: pulse;
        animation-iteration-count: 1;
        animation-duration: 0.65s;
      }
    }
    tfoot {
      border-top: 1px solid gray;
      font-weight: 500;
      font-style: italic;
      td:last-of-type {
        text-align: right;
      }
    }
  }

  @keyframes pulse {
    0% {
      font-weight: 100;
    }
    75% {
      font-weight: 500;
    }
    100% {
      font-weight: 100;
    }
  }
}

@mixin on-circle($item-count, $circle-size, $item-size) {
  position: relative;
  width: $circle-size;
  height: $circle-size;
  padding: 0;
  border-radius: 50%;
  list-style: none;

  > * {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $item-size;
    height: $item-size;
    margin: -($item-size / 2);

    $angle: (360 / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: rotate($rot * 1deg)
          translate($circle-size / 2)
          rotate($rot * -1deg);
      }

      $rot: $rot + $angle;
    }
  }
}

@mixin generate-circle($circle-size, $letter-size) {
  &.four {
    @include on-circle(4, $circle-size, $letter-size);
  }
  &.five {
    @include on-circle(5, $circle-size, $letter-size);
  }
  &.six {
    @include on-circle(6, $circle-size, $letter-size);
  }
  &.seven {
    @include on-circle(7, $circle-size, $letter-size);
  }
  &.eight {
    @include on-circle(8, $circle-size, $letter-size);
  }
  &.nine {
    @include on-circle(9, $circle-size, $letter-size);
  }
  &.ten {
    @include on-circle(10, $circle-size, $letter-size);
  }
  &.eleven {
    @include on-circle(11, $circle-size, $letter-size);
  }
  &.twelve {
    @include on-circle(12, $circle-size, $letter-size);
  }
  &.thirteen {
    @include on-circle(13, $circle-size, $letter-size);
  }
  &.fourteen {
    @include on-circle(14, $circle-size, 3$letter-size);
  }
}

.tile {
  display: inline-block;
  border: 1px solid lightgray;
  margin: 2px;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  line-height: 32px;
  vertical-align: middle;
  text-align: center;
  background: radial-gradient(
    circle,
    rgba(193, 174, 31, 0.1) 1%,
    rgba(217, 248, 79, 0.1) 100%
  );
}

.found {
  font-weight: bold;
  background: radial-gradient(
    circle,
    rgba(193, 174, 31, 0.23) 1%,
    rgba(217, 248, 79, 0.23) 100%
  );
  animation-name: fadeIn;
  animation-duration: 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  45% {
    opacity: 0.75;
  }
  60% {
    opacity: 50%;
  }
  100% {
    opacity: 1;
  }
}

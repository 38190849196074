#silkScreen {
  background: rgba(256, 256, 256, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 2;

  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}

#winnerWinnerChickenDinner {
  background: rgb(0, 25, 36);
  background: linear-gradient(
    -15deg,
    rgba(228, 228, 228, 0.95) 1%,
    rgba(2, 125, 193, 0.99) 80%
  );

  box-shadow: 10px 5px 5px grey;

  border-radius: 6px;

  text-align: center;

  width: 300px;
  height: 75px;
  left: 50%;
  top: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border: 1px solid grey;
  z-index: 3;
  padding: 6px;
  animation: linear 1.5s fadeIn;

  display: block;
  position: absolute;

  div.message {
    color: #fff;
    font-size: x-large;
    font-weight: bolder;
    text-shadow: 2px 2px 2px rgba(82, 148, 184, 0.735);
  }

  .button {
    background: rgba(255, 255, 255, 0.358);
    color: rgb(30, 30, 30);
    border: 1px solid grey;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    position: absolute;
    bottom: 6px;
    width: calc(100% - 21px);
    left: 6px;
    padding: 3px;
  }
}

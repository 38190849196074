.words {
  overflow: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  h2 {
    margin: 0.3rem 0rem 0.1rem 0.25rem;
    position: relative;
    top: 0px;

    flex-shrink: 0;
  }

  ul {
    margin: 0px;
    padding: 0px;

    flex-grow: 1;
    overflow: auto;
    min-height: 2em;
    li {
      margin: 3px;
    }
  }
}

.help {
  h1 {
    margin: 1rem 0.5rem 0.25rem 0rem;
    padding-bottom: 0.5rem;
    color: #111;
    border-bottom: 1px solid #111;
  }

  h2 {
    margin: 1rem 0.5rem 0.25rem 0rem;
    color: #222;
    border-bottom: 1px solid #222;
  }

  h3 {
    margin: 1rem 0.5rem 0.25rem 0rem;
    color: #333;
    border-bottom: 1px solid #333;
  }

  ol li {
    margin-bottom: 1rem;
  }
}
